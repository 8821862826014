import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  aboutAll,
  aboutDetail,
  content,
  cv,
  cvDescription,
  cvEntry,
  cvTime,
  description,
  image,
  imageWrapper,
  info,
} from "./styles/about.module.scss";

import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";

const About = ({ data }) => {
  const aboutData = data.strapiAbout;
  const cvEntries = data.strapiAbout?.cv.map((entry) => {
    return entry;
  });

  return (
    <div className={aboutDetail}>
      <section className={info}>
        <div className={`fade-in`}>
          <h2>about</h2>
        </div>
        <p>{aboutData.info}</p>
      </section>
      <section className={content}>
        <div className={aboutAll}>
          <div className={imageWrapper}>
            <GatsbyImage
              className={image}
              image={getImage(aboutData.image.localFile)}
              alt="Erich Ganster"
              objectPosition="center"
            ></GatsbyImage>
          </div>
          <ReactMarkdown className={description}>
            {aboutData.description}
          </ReactMarkdown>
        </div>
        <div className={cv}>
          {cvEntries.map((entry) => (
            <div className={cvEntry} key={entry.time}>
              <p className={cvTime}>{entry.time}</p>
              <p className={cvDescription}>{entry.description}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

About.seoOptions = {
  title: "About",
};
export default About;

export const query = graphql`
  query {
    strapiAbout {
      info
      description
      cv {
        description
        time
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  }
`;
