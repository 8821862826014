// extracted by mini-css-extract-plugin
export var dNone = "about-module--d-none--2Xxou";
export var dBlock = "about-module--d-block--W4PnI";
export var dInline = "about-module--d-inline--pefpC";
export var dFlex = "about-module--d-flex--TVv7P";
export var dMdNone = "about-module--d-md-none--zDIop";
export var dMdBlock = "about-module--d-md-block--iVevP";
export var dMdFlex = "about-module--d-md-flex--VBs+M";
export var dLgFlex = "about-module--d-lg-flex--03K9l";
export var dLgNone = "about-module--d-lg-none--DxLR5";
export var dLgBlock = "about-module--d-lg-block--bvyJR";
export var dLgInline = "about-module--d-lg-inline--htGyN";
export var aboutDetail = "about-module--aboutDetail--0gwyp";
export var info = "about-module--info--vxsdU";
export var imageWrapper = "about-module--imageWrapper--7aFam";
export var description = "about-module--description--5TgtL";
export var cv = "about-module--cv--wSFCl";
export var cvEntry = "about-module--cvEntry--ps2us";
export var cvTime = "about-module--cvTime--9QTBZ";
export var cvDescription = "about-module--cvDescription--9heIB";
export var aboutAll = "about-module--aboutAll--Admmu";
export var image = "about-module--image--+MHH+";